import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import RoundLogo from "../assets/images/roundbubba.png";

const StickyGrid = styled(Box)(({ theme }) => ({
  // position: 'sticky',
  top: 0,
  zIndex: 1000,
  backgroundColor: "transparent",
  boxShadow: "none",
  margin: "0 0 20px",

  [theme.breakpoints.down("md")]: {
    padding: "0 ",
  },
}));

const Header = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <StickyGrid>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {isSmallScreen ? (
          <>
            {" "}
            <IconButton
              sx={{
                color: "#d951e7",
                backgroundColor: "#fff",
                border: "3px solid #d951e7",
                borderRadius: "50%",
                ":hover": {
                  background: "#f4c5f9",
                  color: "#d951e7",
                },
              }}
              variant="contained"
              size="large"
              color="secondary"
              component={Link}
              target="_blank"
              to={"https://www.bubba.xyz/"}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Button
              sx={{
                height: 64,
                fontSize: 30,
                color: "#d951e7",
                backgroundColor: "#fff",
                border: "3px solid #d951e7",
                borderRadius: "16px",
                ":hover": {
                  background: "#f4c5f9",
                  color: "#d951e7",
                },
                [theme.breakpoints.down("md")]: {
                  height: 48,
                  fontSize: 16,
                },
              }}
              variant="contained"
              size="large"
              color="secondary"
              component={Link}
              startIcon={<KeyboardBackspaceIcon />}
              target="_blank"
              to={"https://www.bubba.xyz/"}
            >
              Go back home
            </Button>
          </>
        )}

        <Box
          component={"img"}
          src={Logo}
          alt="bubba"
          sx={{
            height: "80px",
            // marginRight: "130px",
            [theme.breakpoints.down("md")]: {
              marginRight: "0",
              height: 56,
            },
          }}
        />
        <Box
          component={"img"}
          src={RoundLogo}
          alt="bubba"
          sx={{
            height: "80px",
            [theme.breakpoints.down("md")]: {
              height: 56,
            },
          }}
        />
      </Stack>
    </StickyGrid>
  );
};

export default Header;
