import { Box, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Box my={5}>
      <Typography
        sx={{
          color: "#ffffff",
          "-webkit-text-stroke-width": "1px",
          "-webkit-text-stroke-color": "black",
          textAlign: "center",
          textTransform: "uppercase",
        }}
        variant="h1"
        color={"secondary"}
        textAlign={"center"}
      >
        COPYRIGHT &copy; 2024. ALL RIGHTS RESERVED.
      </Typography>
    </Box>
  );
};

export default Footer;
