import { styled } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import BackgroundMobImg from "../assets/images/BG-Mob.png";
import BackgroundImg from "../assets/images/BG.png";
import Footer from "./Footer";
import Header from "./Header";

const MainWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "100vh",
  // overflow: 'hidden',
  padding: "40px 120px 0",

  [theme.breakpoints.down("lg")]: {
    padding: "30px 15px",
  },

  [theme.breakpoints.down("md")]: {
    padding: "30px 15px",
  },

  "@media (min-width:768px) and (max-width:1224px)": {
    padding: "40px 60px 0",
  },
}));

const PageWrapper = styled("div")(({ theme }) => ({
  backgroundImage: `url(${BackgroundImg})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",

  [theme.breakpoints.down("md")]: {
    backgroundPosition: "top right",
  },

  [theme.breakpoints.down("sm")]: {
    backgroundImage: `url(${BackgroundMobImg})`,
    // backgroundSize: "cover",
    "-webkit-background-size": "117%",
    // backgroundSize: "117%",
    backgroundPosition: "top right",
  },
}));

const Layout = () => {
  return (
    <PageWrapper>
      <MainWrapper>
        <Header />
        <Outlet />
        <Footer />
      </MainWrapper>
    </PageWrapper>
  );
};

export default Layout;
