import BlockIcon from "@mui/icons-material/Block";
import {
  Box,
  Grid,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Slider from "react-slick";
import BubbaImg from "../assets/images/bubba.png";
import LeftArrow from "../assets/images/svg/left-arrow.svg";
import RightArrow from "../assets/images/svg/right-arrow.svg";
import data from "../utils/index.json";

const PageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.spacing(3),
  background: "#dfd6ffee",
  // border: `2px solid rgba(0,0,0,.12)`,
  width: "35%",
  position: "relative",

  [theme.breakpoints.down("lg")]: {
    width: "40%",
    padding: theme.spacing(3),
  },

  [theme.breakpoints.down("md")]: {
    width: "-webkit-fill-available",
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
  },
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(6),
  fontWeight: 800,
  color: "#f4a6be",
  "-webkit-text-stroke-width": "0.5px",
  "-webkit-text-stroke-color": "black",
  textTransform: "uppercase",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "36px",
  fontWeight: 600,
  textTransform: "uppercase",
  color: "#f4a6be",
  "-webkit-text-stroke-width": "0.5px",
  "-webkit-text-stroke-color": "black",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
}));

const MiniBoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  gap: theme.spacing(1.75),
}));

const MiniBox = styled(Box)(({ theme }) => ({
  width: "64px",
  height: "64px",
  borderRadius: theme.spacing(2),
  background: `url(${BubbaImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",

  // background: theme.palette.secondary.main,
  border: `3px solid ${theme.palette.primary.light}`,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  // [theme.breakpoints.down("sm")]: {
  //   width: 40,
  //   height: 40,
  // },

  ":hover": {
    // border: `3px solid ${theme.palette.primary.main}`,
    // background: theme.palette.primary.light,
  },

  ":hover svg": {
    color: theme.palette.secondary.main,
  },
}));

const MinBoxImg = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  borderRadius: theme.spacing(2),
  border: "3px solid transparent",
}));

function SampleNextArrow(props) {
  const theme = useTheme();

  const { className, onClick } = props;
  return (
    <Box
      className={className}
      sx={{
        background: "linear-gradient(90deg,rgba(218,231,250,0) 0,#dae7fa 30%)",
        borderRadius: theme.spacing(1),
        width: "40px",
        height: "66px",
        padding: "6px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "absolute",
        right: "-1px",

        // [theme.breakpoints.down("sm")]: {
        //   width: "10px",
        //   height: "60%",
        // },

        ":hover": {
          background:
            "linear-gradient(90deg,rgba(218,231,250,0) 0,#dae7fa 30%)",
        },

        "&.slick-next:before": {
          content: '""',
          display: "inline-block",
          width: "20px",
          height: "20px",
          backgroundImage: `url(${RightArrow})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const theme = useTheme();

  const { className, onClick } = props;
  return (
    <Box
      className={className}
      sx={{
        background: "linear-gradient(-90deg,rgba(218,231,250,0) 0,#dae7fa 30%)",
        borderRadius: theme.spacing(1),
        width: "40px",
        height: "66px",
        padding: "6px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: "-1px",
        zIndex: 1,

        // [theme.breakpoints.down("sm")]: {
        //   width: "10px",
        //   height: "60%",
        // },

        ":hover": {
          background:
            "linear-gradient(-90deg,rgba(218,231,250,0) 0,#dae7fa 30%)",
        },

        "&.slick-prev:before": {
          content: '""',
          display: "inline-block",
          width: "20px",
          height: "20px",
          backgroundImage: `url(${LeftArrow})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
      }}
      onClick={onClick}
    />
  );
}

const Editor = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [backgroundCurrentSlide, setBackgroundCurrentSlide] = useState(0);
  const [bodyCurrentSlide, setBodyCurrentSlide] = useState(0);
  const [clothesCurrentSlide, setClothesCurrentSlide] = useState(0);
  const [eyesCurrentSlide, setEyesCurrentSlide] = useState(0);
  const [headCurrentSlide, setHeadCurrentSlide] = useState(0);
  const [mouthCurrentSlide, setMouthCurrentSlide] = useState(0);

  const totalBackgroundSlides = Object.entries(data.background).length;
  const totalBodySlides = Object.entries(data.body).length;
  const totalClothesSlides = Object.entries(data.clothes).length;
  const totalEyesSlides = Object.entries(data.eyes).length;
  const totalHeadSlides = Object.entries(data.head).length;
  const totalMouthSlides = Object.entries(data.mouth).length;

  const settingBackground = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSmallScreen ? 5 : 6,
    slidesToScroll: 1,
    nextArrow:
      backgroundCurrentSlide <
      totalBackgroundSlides - `${isSmallScreen ? 5 : 6}` ? (
        <SampleNextArrow />
      ) : (
        <></>
      ),
    prevArrow: backgroundCurrentSlide > 0 ? <SamplePrevArrow /> : <></>,
    afterChange: (current) => setBackgroundCurrentSlide(current),
  };

  const settingsBody = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSmallScreen ? 5 : 6,
    slidesToScroll: 1,
    nextArrow:
      bodyCurrentSlide < totalBodySlides - `${isSmallScreen ? 5 : 6}` ? (
        <SampleNextArrow />
      ) : (
        <></>
      ),
    prevArrow: bodyCurrentSlide > 0 ? <SamplePrevArrow /> : <></>,
    afterChange: (current) => setBodyCurrentSlide(current),
  };

  const settingsClothes = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSmallScreen ? 5 : 6,
    slidesToScroll: 1,
    nextArrow:
      clothesCurrentSlide < totalClothesSlides - `${isSmallScreen ? 5 : 6}` ? (
        <SampleNextArrow />
      ) : (
        <></>
      ),
    prevArrow: clothesCurrentSlide > 0 ? <SamplePrevArrow /> : <></>,
    afterChange: (current) => setClothesCurrentSlide(current),
  };

  const settingsEyes = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSmallScreen ? 5 : 6,
    slidesToScroll: 1,
    nextArrow:
      eyesCurrentSlide < totalEyesSlides - `${isSmallScreen ? 5 : 6}` ? (
        <SampleNextArrow />
      ) : (
        <></>
      ),
    prevArrow: eyesCurrentSlide > 0 ? <SamplePrevArrow /> : <></>,
    afterChange: (current) => setEyesCurrentSlide(current),
  };

  const settingsHead = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSmallScreen ? 5 : 6,
    slidesToScroll: 1,
    nextArrow:
      headCurrentSlide < totalHeadSlides - `${isSmallScreen ? 5 : 6}` ? (
        <SampleNextArrow />
      ) : (
        <></>
      ),
    prevArrow: headCurrentSlide > 0 ? <SamplePrevArrow /> : <></>,
    afterChange: (current) => setHeadCurrentSlide(current),
  };

  const settingsMouth = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSmallScreen ? 5 : 6,
    slidesToScroll: 1,
    nextArrow:
      mouthCurrentSlide < totalMouthSlides - `${isSmallScreen ? 5 : 6}` ? (
        <SampleNextArrow />
      ) : (
        <></>
      ),
    prevArrow: mouthCurrentSlide > 0 ? <SamplePrevArrow /> : <></>,
    afterChange: (current) => setMouthCurrentSlide(current),
  };

  return (
    <PageWrapper>
      <Stack direction={"column"} gap={3}>
        <MainTitle>Create your Bubba</MainTitle>

        {/* Background */}
        <Box>
          <SubTitle>Background</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.background).length > 6 ? (
                <Slider {...settingBackground}>
                  {Object.entries(data.background).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setBubba({
                            ...props.bubba,
                            background: key,
                          })
                        }
                        sx={{
                          // background:
                          //   props.bubba.background === key &&
                          //   theme.palette.primary.light,
                          border:
                            props.bubba.background === key &&
                            `3px solid ${theme.palette.primary.main}`,
                        }}
                      >
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/Background/${value}`)}
                        />
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.background).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setBubba({
                          ...props.bubba,
                          background: key || "",
                        })
                      }
                      sx={{
                        background:
                          props.bubba.background === key &&
                          theme.palette.primary.light,
                        border:
                          props.bubba.background === key &&
                          `3px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      <MinBoxImg
                        component="img"
                        src={require(`../assets/images/Background/${value}`)}
                      />
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* Body */}
        <Box>
          <SubTitle>Body</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.body).length > 6 ? (
                <Slider {...settingsBody}>
                  {Object.entries(data.body).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setBubba({
                            ...props.bubba,
                            body: key || "",
                          })
                        }
                        sx={{
                          // background:
                          //   props.bubba.body === key &&
                          //   theme.palette.primary.light,
                          border:
                            props.bubba.body === key &&
                            `3px solid ${theme.palette.primary.main}`,
                        }}
                      >
                        {value ? (
                          <MinBoxImg
                            component="img"
                            src={require(`../assets/images/Body/${value}`)}
                          />
                        ) : (
                          <BlockIcon
                            sx={{
                              color: theme.palette.primary.light,
                            }}
                            onClick={(e) =>
                              props.setBubba({
                                ...props.bubba,
                                body: "",
                              })
                            }
                          />
                        )}
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.body).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setBubba({
                          ...props.bubba,
                          body: key,
                        })
                      }
                      sx={{
                        background:
                          props.bubba.body === key &&
                          theme.palette.primary.light,
                        border:
                          props.bubba.body === key &&
                          `3px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {value ? (
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/Body/${value}`)}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                          onClick={(e) =>
                            props.setBubba({
                              ...props.bubba,
                              body: "",
                            })
                          }
                        />
                      )}
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* Clothes */}
        <Box>
          <SubTitle>Clothes</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.clothes).length > 6 ? (
                <Slider {...settingsClothes}>
                  {Object.entries(data.clothes).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setBubba({
                            ...props.bubba,
                            clothes: key || "",
                          })
                        }
                        sx={{
                          // background:
                          //   props.bubba.clothes === key &&
                          //   theme.palette.primary.light,
                          border:
                            props.bubba.clothes === key &&
                            `3px solid ${theme.palette.primary.main}`,
                        }}
                      >
                        {value ? (
                          <MinBoxImg
                            component="img"
                            src={require(`../assets/images/Clothes/${value}`)}
                          />
                        ) : (
                          <BlockIcon
                            sx={{
                              color: theme.palette.primary.light,
                            }}
                            onClick={(e) =>
                              props.setBubba({
                                ...props.bubba,
                                clothes: "",
                              })
                            }
                          />
                        )}
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.clothes).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setBubba({
                          ...props.bubba,
                          clothes: key,
                        })
                      }
                      sx={{
                        background:
                          props.bubba.clothes === key &&
                          theme.palette.primary.light,
                        border:
                          props.bubba.clothes === key &&
                          `3px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {value ? (
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/Clothes/${value}`)}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                          onClick={(e) =>
                            props.setBubba({
                              ...props.bubba,
                              clothes: "",
                            })
                          }
                        />
                      )}
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* Eyes */}
        <Box>
          <SubTitle>Eyes</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.eyes).length > 6 ? (
                <Slider {...settingsEyes}>
                  {Object.entries(data.eyes).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setBubba({
                            ...props.bubba,
                            eyes: key || "",
                          })
                        }
                        sx={{
                          // background:
                          //   props.bubba.eyes === key &&
                          //   theme.palette.primary.light,
                          border:
                            props.bubba.eyes === key &&
                            `3px solid ${theme.palette.primary.main}`,
                        }}
                      >
                        {value ? (
                          <MinBoxImg
                            component="img"
                            src={require(`../assets/images/Eyes/${value}`)}
                          />
                        ) : (
                          <BlockIcon
                            sx={{
                              color: theme.palette.primary.light,
                            }}
                            onClick={(e) =>
                              props.setBubba({
                                ...props.bubba,
                                eyes: "",
                              })
                            }
                          />
                        )}
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.eyes).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setBubba({
                          ...props.bubba,
                          eyes: key,
                        })
                      }
                      sx={{
                        background:
                          props.bubba.eyes === key &&
                          theme.palette.primary.light,
                        border:
                          props.bubba.eyes === key &&
                          `3px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {value ? (
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/Eyes/${value}`)}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                          onClick={(e) =>
                            props.setBubba({
                              ...props.bubba,
                              eyes: "",
                            })
                          }
                        />
                      )}
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* Head */}
        <Box>
          <SubTitle>Head</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.head).length > 6 ? (
                <Slider {...settingsHead}>
                  {Object.entries(data.head).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setBubba({
                            ...props.bubba,
                            head: key || "",
                          })
                        }
                        sx={{
                          // background:
                          //   props.bubba.head === key &&
                          //   theme.palette.primary.light,
                          border:
                            props.bubba.head === key &&
                            `3px solid ${theme.palette.primary.main}`,
                        }}
                      >
                        {value ? (
                          <MinBoxImg
                            component="img"
                            src={require(`../assets/images/Head/${value}`)}
                          />
                        ) : (
                          <BlockIcon
                            sx={{
                              color: theme.palette.primary.light,
                            }}
                            onClick={(e) =>
                              props.setBubba({
                                ...props.bubba,
                                head: "",
                              })
                            }
                          />
                        )}
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.head).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setBubba({
                          ...props.bubba,
                          head: key || "",
                        })
                      }
                      sx={{
                        background:
                          props.bubba.head === key &&
                          theme.palette.primary.light,
                        border:
                          props.bubba.head === key &&
                          `3px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {value ? (
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/Head/${value}`)}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                          onClick={(e) =>
                            props.setBubba({
                              ...props.bubba,
                              head: "",
                            })
                          }
                        />
                      )}
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* Mouth */}
        <Box>
          <SubTitle>Mouth</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.mouth).length > 6 ? (
                <Slider {...settingsMouth}>
                  {Object.entries(data.mouth).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setBubba({
                            ...props.bubba,
                            mouth: key || "",
                          })
                        }
                        sx={{
                          // background:
                          //   props.bubba.mouth === key &&
                          //   theme.palette.primary.light,
                          border:
                            props.bubba.mouth === key &&
                            `3px solid ${theme.palette.primary.main}`,
                        }}
                      >
                        {value ? (
                          <MinBoxImg
                            component="img"
                            src={require(`../assets/images/Mouth/${value}`)}
                          />
                        ) : (
                          <BlockIcon
                            sx={{
                              color: theme.palette.primary.main,
                            }}
                            onClick={(e) =>
                              props.setBubba({
                                ...props.bubba,
                                mouth: "",
                              })
                            }
                          />
                        )}
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.mouth).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setBubba({
                          ...props.bubba,
                          mouth: key || "",
                        })
                      }
                      sx={{
                        background:
                          props.bubba.mouth === key &&
                          theme.palette.primary.light,
                        border:
                          props.bubba.mouth === key &&
                          `3px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {value ? (
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/Mouth/${value}`)}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                          onClick={(e) =>
                            props.setBubba({
                              ...props.bubba,
                              mouth: "",
                            })
                          }
                        />
                      )}
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </PageWrapper>
  );
};

export default Editor;
