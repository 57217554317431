import React, { useRef } from "react";
import { Box, Button, Grid, Stack, styled, useTheme } from "@mui/material";
import data from "../utils/index.json";
import html2canvas from "html2canvas";
import CasinoIcon from "@mui/icons-material/Casino";
import DownloadIcon from "@mui/icons-material/Download";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

const PageWrapper = styled(Box)(({ theme }) => ({
  width: "55%",
  position: "sticky",
  top: "5%",

  "@media (min-width:1560px) and (max-width:1824px)": {
    width: "65%",
  },

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const BubbaBody = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "100%",
  objectFit: "cover",
}));

const BubbaClothes = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "100%",
  objectFit: "cover",
}));

const BubbaEyes = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "100%",
  objectFit: "cover",
}));

const BubbaHead = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "100%",
  objectFit: "cover",
}));

const BubbaMouth = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "100%",
  objectFit: "cover",
}));

const AvatarLoader = (props) => {
  const theme = useTheme();
  const boxRef = useRef();

  const handleGenerate = () => {
    const randomBubba = {
      background: getRandomId(data.background),
      body: getRandomId(data.body),
      clothes: getRandomId(data.clothes),
      eyes: getRandomId(data.eyes),
      head: getRandomId(data.head),
      mouth: getRandomId(data.mouth),
    };

    function getRandomId(obj) {
      const keys = Object.keys(obj);
      const randomIndex = Math.floor(Math.random() * keys.length);
      return keys[randomIndex];
    }

    props.setBubba(randomBubba);
  };

  const download = () => {
    html2canvas(boxRef.current, {
      // width: 500,
      // height: 500,
      // scale: 1,
      // useCORS: true,
    }).then((canvas) => {
      const link = document.createElement("a");
      link.download = "bubba.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  const handleReset = () => {
    props.setBubba({
      background: "BG1",
      body: "BODY1",
      clothes: "CLOTHES1",
      eyes: "EYES1",
      head: "HEAD1",
      mouth: "MOUTH1",
    });
  };

  return (
    <PageWrapper>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: "20px",
            border: "10px solid #19334a",
            height: "370px",

            [theme.breakpoints.down("md")]: {
              height: "350px",
            },
          }}
        >
          {data.background[props.bubba.background] ? (
            <Box
              ref={boxRef}
              sx={{
                height: "100%",
                // width: '500px',
                // height: '500px',
                position: "relative",
                backgroundImage: `url(${require(`../assets/images/Background/${
                  data.background[props.bubba.background]
                }`)})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                zIndex: -1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {data.body[props.bubba.body] && (
                <BubbaBody
                  component={"img"}
                  src={require(`../assets/images/Body/${
                    data.body[props.bubba.body]
                  }`)}
                />
              )}
              {data.clothes[props.bubba.clothes] && (
                <BubbaClothes
                  component={"img"}
                  src={require(`../assets/images/Clothes/${
                    data.clothes[props.bubba.clothes]
                  }`)}
                />
              )}
              {data.eyes[props.bubba.eyes] && (
                <BubbaEyes
                  component={"img"}
                  src={require(`../assets/images/Eyes/${
                    data.eyes[props.bubba.eyes]
                  }`)}
                />
              )}
              {data.head[props.bubba.head] && (
                <BubbaHead
                  component={"img"}
                  src={require(`../assets/images/Head/${
                    data.head[props.bubba.head]
                  }`)}
                />
              )}
              {data.mouth[props.bubba.mouth] && (
                <BubbaMouth
                  component={"img"}
                  src={require(`../assets/images/Mouth/${
                    data.mouth[props.bubba.mouth]
                  }`)}
                />
              )}
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} mt={3}>
          <Stack direction={"column"} gap={1.75} mb={3}>
            <Button
              sx={{
                height: 64,
                fontSize: 30,
                color: "#d951e7",
                backgroundColor: "#fff",
                border: "3px solid #d951e7",
                borderRadius: "16px",

                ":hover": {
                  background: "#f4c5f9",
                  color: "#d951e7",
                },
                [theme.breakpoints.down("md")]: {
                  height: 48,
                  fontSize: 24,
                },
              }}
              variant="contained"
              size="large"
              color="secondary"
              fullWidth
              startIcon={<RotateLeftIcon />}
              onClick={handleReset}
            >
              Reset Bubba
            </Button>
            <Button
              sx={{
                height: 64,
                fontSize: 30,
                color: "#d951e7",
                backgroundColor: "#fff",
                border: "3px solid #d951e7",
                borderRadius: "16px",

                ":hover": {
                  background: "#f4c5f9",
                  color: "#d951e7",
                },
                [theme.breakpoints.down("md")]: {
                  height: 48,
                  fontSize: 24,
                },
              }}
              variant="contained"
              size="large"
              fullWidth
              onClick={handleGenerate}
              startIcon={<CasinoIcon />}
            >
              Generate Random
            </Button>
            <Button
              sx={{
                height: 64,
                fontSize: 30,
                color: "#d951e7",
                backgroundColor: "#fff",
                border: "3px solid #d951e7",
                borderRadius: "16px",

                ":hover": {
                  background: "#f4c5f9",
                  color: "#d951e7",
                },
                [theme.breakpoints.down("md")]: {
                  height: 48,
                  fontSize: 24,
                },
              }}
              variant="contained"
              size="large"
              fullWidth
              onClick={download}
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default AvatarLoader;
