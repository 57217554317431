import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import AvatarLoader from "../components/AvatarLoader";
import Editor from "../components/Editor";

const EditorPage = () => {
  const theme = useTheme();
  const [bubba, setBubba] = useState({
    background: "BG1",
    body: "BODY1",
    clothes: "CLOTHES1",
    eyes: "EYES1",
    head: "HEAD1",
    mouth: "MOUTH1",
  });
  useEffect(() => {
    console.log("bubba", bubba);
  }, [bubba]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column-reverse" : "row",
          position: "relative",
          width: "100%",
          gap: isSmallScreen && 2,

          "@media (min-width:768px) and (max-width:1024px)": {
            gap: theme.spacing(5),
          },

          "@media (min-width:1024px) and (max-width:1350px)": {
            gap: theme.spacing(4),
          },

          "@media (min-width:1351px) and (max-width:1560px)": {
            gap: theme.spacing(7.5),
          },

          "@media (min-width:1560px) and (max-width:1824px)": {
            gap: theme.spacing(4),
          },

          "@media (min-width:1824px)": {
            gap: theme.spacing(9.5),
          },
          "@media (min-width:2024px)": {
            gap: theme.spacing(11),
          },
          "@media (min-width:2170px)": {
            gap: theme.spacing(14),
          },
          "@media (min-width:2500px)": {
            gap: theme.spacing(20),
          },
        }}
      >
        <Editor bubba={bubba} setBubba={setBubba} />
        <Box
          sx={{
            flexGrow: 1,
            padding: "0 40px",

            "@media (min-width:1024px) and (max-width:1560px)": {
              padding: "0 ",
            },
            "@media (min-width:1560px) and (max-width:1824px)": {
              // padding: "0 ",
            },

            [theme.breakpoints.down("md")]: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // padding: "0 40px 0 43px",
            },
            "@media (max-width:599px) ": {
              padding: "0px 57px 0 60px",
            },
            "@media (max-width:540px) ": {
              padding: "0px 52px 0 55px",
            },
            "@media (max-width:490px) ": {
              padding: "0px 48px 0 51px",
            },
            "@media (max-width:450px) ": {
              padding: "0px 44px 0 46px",
            },
            "@media (max-width:420px) ": {
              padding: "0px 39px 0 42px",
            },
            "@media (max-width:390px) ": {
              padding: "0px 35px 0 38px",
            },
            "@media (max-width:375px)": {
              padding: "0 34px 0 36px",
            },
            "@media (max-width:350px)": {
              padding: "0 30px 0 33px",
            },
            "@media (max-width:330px)": {
              padding: "0 24px 0 30px",
            },
            "@media (max-width:320px)": {
              padding: "0px 18px 0 30px",
            },
          }}
        >
          <AvatarLoader bubba={bubba} setBubba={setBubba} />
        </Box>
      </Box>
    </Box>
  );
};

export default EditorPage;
